.banner-section {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-color: #111;

  .banner-left {
    //height: 100%;
    width: 100%;
    max-width: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-wrapper {
      padding: 30px;
      padding-bottom: 100px;
      box-sizing: border-box;
      position: relative;
      h1 {
        color: #fff;
        margin: 0;
      }
      p {
        color: #eee;
        font-weight: normal;
      }
    }
  }
  .banner-wrapper {
    height: 400px;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .banner-background-image {
      width: 100%;
      height: 100%;
      position: absolute;
      background-position: center;
      background-size: cover;
      opacity: 1;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      video {
        position: absolute;
        height: 100vh;
        filter: blur(4px);
        @media screen and (min-width: 1600px) {
          height: auto;
          width: 100vw;
        }
      }
    }

    .banner-text {
      position: relative;
      @extend .multiply;
      background: rgba(0, 83, 84, 1);
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: #fff;
      padding: 0 20px;
      padding-top: 40px;
      .banner-h1 {
        position: relative;
        margin: 0;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        display: inline-block;
        font-size: 22vw;
        line-height: 20vw;
      }
      .banner-h2 {
        margin: 0;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-size: 8vw;
      }

      @media screen and (min-width: 780px) {
        .banner-h1 {
          font-size: 15vw;
        }
        .banner-h2 {
          font-size: 5vw;
        }
      }
    }
  }

  .banner-bottom {
    flex: 1;
    width: 100%;
    //background: rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 10px;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 20px;
    .social-media-links {
      height: 50px;
      position: relative;
      .icon {
        color: #fff;
        font-size: 30px;
        margin: 10px;
      }
    }
  }

  @media screen and (min-width: 780px) {
    flex-direction: row-reverse;
    align-items: center;
    height: 70vh;
    overflow: hidden;
    .banner-left {
      flex: 3;
      height: 100%;
      &:before {
        content: '';
        display: block;
        width: 200px;
        height: 100%;
        background-color: #111;
        transform: skewX(-8deg);
        position: absolute;
        top: 0;
        right: -50px;
      }
    }
    .banner-wrapper {
      .banner-text {
        padding-left: 70px;
        padding-top: 0px;
        .banner-h1 {
          line-height: 12vw;
        }
      }
      height: 100%;
      flex: 5;
    }
  }
}

.multiply {
  mix-blend-mode: multiply;
}

.coursesPage .banner-section {
  .banner-wrapper .banner-background-image {
    background-image: url('../assets/graphics/a1.jpg');
  }
}
.corporatePage .banner-section {
  .banner-wrapper .banner-background-image {
    background-image: url('../assets/graphics/corporate2.jpg');
  }
}
