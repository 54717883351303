html {
    margin: 0;
    padding: 0;
    width: 100%;
}
body {
    margin: 0;
    width: 100%;
    scroll-behavior: smooth;
    transition-duration: 0.2s;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #111;
    overflow-x: hidden;
    * {
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: teal;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

input,
textarea,
select {
    font-family: "Roboto", sans-serif;
}

.white-text {
    color: #fff !important;
}

.section-heading {
    position: relative;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    .title {
        font-size: 22px;
        position: relative;
        color: #007a8a;
        text-transform: uppercase;
        text-align: center;
    }
    p {
        text-align: center;
    }
    @media screen and (min-width: 780px) {
        padding: 100px;
    }
    @media screen and (min-width: 980px) {
        .title {
            font-size: 36px;
        }
    }
}

.text-left {
    text-align: left !important;
}
.contact-button {
    border: 1px solid;
    padding: 15px;
    background: none;
    color: #fff;
    border-color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    &.dark {
        color: #111;
        border-color: #111;
    }
    &.teal {
        color: #fff;
        background-color: #007a8a;
    }
}
