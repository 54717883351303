.plain-white-section {
    background-color: #fff;
    position: relative;
    .section-heading {
        text-align: center;
        max-width: 1100px;
        margin: 0 auto;
        padding: 20px;
        position: relative;
        p {
            color: #222;
            &.text-left {
                text-align: left;
            }
        }
        @media screen and (min-width: 768px) {
            padding: 100px;
        }
    }
}
