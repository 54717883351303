.corporatePage .tools {
    display: flex;
    overflow: hidden;
    background: #111;
    .right-section {
        flex: 1;
        text-align: center;
        position: relative;
    }

    @media screen and (min-width: 780px) {
        .left-section {
            flex: 2;
            background-image: url("./../../../assets/graphics/stats.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            opacity: 0.4;
        }
        .right-section {
            flex: 4;
            &:before {
                content: "";
                display: block;
                width: 200px;
                height: 100%;
                background-color: #111;
                transform: skewX(8deg);
                position: absolute;
                top: 0;
                left: -50px;
            }
        }
    }
}
